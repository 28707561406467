@import url(https://fonts.googleapis.com/css?family=Inter|Roboto|Rubik|Bebas%20Neue&display=swap);
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  10% {
    -webkit-transform: scale(1.1, 0.9) translateY(0);
            transform: scale(1.1, 0.9) translateY(0); }
  30% {
    -webkit-transform: scale(0.9, 1.1) translateY(-100px);
            transform: scale(0.9, 1.1) translateY(-100px); }
  50% {
    -webkit-transform: scale(1.05, 0.95) translateY(0);
            transform: scale(1.05, 0.95) translateY(0); }
  57% {
    -webkit-transform: scale(1, 1) translateY(-7px);
            transform: scale(1, 1) translateY(-7px); }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); } }
@keyframes bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  10% {
    -webkit-transform: scale(1.1, 0.9) translateY(0);
            transform: scale(1.1, 0.9) translateY(0); }
  30% {
    -webkit-transform: scale(0.9, 1.1) translateY(-100px);
            transform: scale(0.9, 1.1) translateY(-100px); }
  50% {
    -webkit-transform: scale(1.05, 0.95) translateY(0);
            transform: scale(1.05, 0.95) translateY(0); }
  57% {
    -webkit-transform: scale(1, 1) translateY(-7px);
            transform: scale(1, 1) translateY(-7px); }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); } }

@-webkit-keyframes shine {
  0% {
    background-position: right; } }

@keyframes shine {
  0% {
    background-position: right; } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes fade {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; } }

@keyframes fade {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; } }

@-webkit-keyframes sweepright {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  50% {
    -webkit-transform: translateX(-70%);
            transform: translateX(-70%); } }

@keyframes sweepright {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  50% {
    -webkit-transform: translateX(-70%);
            transform: translateX(-70%); } }

@-webkit-keyframes sweepleft {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  50% {
    -webkit-transform: translateX(70%);
            transform: translateX(70%); } }

@keyframes sweepleft {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  50% {
    -webkit-transform: translateX(70%);
            transform: translateX(70%); } }

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: none;
            transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
            transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
            transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
            transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
            transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
            transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    -webkit-transform: none;
            transform: none; } }

@keyframes wobble {
  0% {
    -webkit-transform: none;
            transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
            transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
            transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
            transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
            transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
            transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes scroll {
  to {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@keyframes scroll {
  to {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

#react-survey-app .switch-wrapper {
  -webkit-animation: fadein 0.05s ease-in;
  animation: fadein 0.05s ease-in;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  height: 100vh; }

@keyframes bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  10% {
    -webkit-transform: scale(1.1, 0.9) translateY(0);
            transform: scale(1.1, 0.9) translateY(0); }
  30% {
    -webkit-transform: scale(0.9, 1.1) translateY(-100px);
            transform: scale(0.9, 1.1) translateY(-100px); }
  50% {
    -webkit-transform: scale(1.05, 0.95) translateY(0);
            transform: scale(1.05, 0.95) translateY(0); }
  57% {
    -webkit-transform: scale(1, 1) translateY(-7px);
            transform: scale(1, 1) translateY(-7px); }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); } }

@keyframes shine {
  0% {
    background-position: right; } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fade {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; } }

@keyframes sweepright {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  50% {
    -webkit-transform: translateX(-70%);
            transform: translateX(-70%); } }

@keyframes sweepleft {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  50% {
    -webkit-transform: translateX(70%);
            transform: translateX(70%); } }

@keyframes wobble {
  0% {
    -webkit-transform: none;
            transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
            transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
            transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
            transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
            transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
            transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    -webkit-transform: none;
            transform: none; } }

@keyframes scroll {
  to {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

.root-app-wrapper.root-app-wrapper-halloween {
  overflow-x: hidden; }

.root-app-wrapper.lander-page {
  background-color: #2d601f;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(180.54deg, #4ea635 -94.87%, #2b5c1d 17.8%, #000000 139.59%);
  overflow-x: hidden; }

.root-app-wrapperv.lander-page {
  background: linear-gradient(180deg, #6ea031 -59.27%, #2b6351 -19.82%, #0b0b0d 91.44%) !important; }

.root-app-wrappervv1.lander-page {
  background: linear-gradient(179.99deg, #6ea031 -59.27%, #2b6351 -14.47%, #0b0b0d 111.9%) !important; }

.root-app-wrappervv.lander-page {
  background: linear-gradient(360deg, #2c5023 -2.63%, rgba(20, 50, 12, 0) 99.76%) !important; }

.root-app-wrapper.light-green-bg {
  background-color: #fbfcfa; }

.root-app-wrapper.dark-green-bg {
  background-color: #2d601f; }

.root-app-wrapper .rds-theme-bold-text {
  color: #4ea635;
  font-weight: 700; }

.root-app-wrapper-halloween.lander-page {
  background-color: #663edf;
  background-image: linear-gradient(179.54deg, #663edf 0.4%, #1e1745 32.07%) !important; }

@media screen and (max-width: 992px) {
  .root-app-wrapper.lander-page {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Flander-back-rays-mobile.png?alt=media&token=01d45977-d57e-4d07-b78a-3f9e606f9f8a"); }
  .root-app-wrapperv4.lander-page {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Flander-back-rays-mobile.png?alt=media&token=01d45977-d57e-4d07-b78a-3f9e606f9f8a");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow-x: hidden; }
  .root-app-wrapperv5.lander-page {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Flander-back-rays-mobile.png?alt=media&token=01d45977-d57e-4d07-b78a-3f9e606f9f8a");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow-x: hidden; }
  .root-app-wrapperv6.lander-page {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Flander-back-rays-mobile.png?alt=media&token=01d45977-d57e-4d07-b78a-3f9e606f9f8a");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow-x: hidden; }
  .root-app-wrapper.billion-cash-bg {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Fbillion-dollar-money-mobile.png?alt=media&token=559a145a-b338-4298-b830-1562f2065a5c");
    background-repeat: repeat-x; }
  .root-app-wrapperv3.lander-page {
    background-color: unset;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Fbillie-dollar-money-mobile.png?alt=media&token=1da24c2a-2c4c-40e2-b9a0-338f3974b70b");
    background-position: top; }
  .root-app-wrapperv2.lander-page {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Flander-green-dollar-mobile.png?alt=media&token=c1e777ea-4342-4126-8f27-998b66501fe2");
    background-position: top; }
  .root-app-wrapper-halloween.lander-page {
    background-image: url("https://assets.realdealsweeps.com/images/realDealSweeps/background/halloween-lander-bg-mobile.png") !important; } }

@media screen and (min-width: 992px) {
  .root-app-wrapper.lander-page {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Flander-back-rays-desktop.png?alt=media&token=39fb27a4-62e8-41ee-90ba-9e9a295d18a9"); }
  .root-app-wrapperv4.lander-page {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Flander-back-rays-desktop.png?alt=media&token=39fb27a4-62e8-41ee-90ba-9e9a295d18a9");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow-x: hidden; }
  .root-app-wrapperv5.lander-page {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Flander-back-rays-desktop.png?alt=media&token=39fb27a4-62e8-41ee-90ba-9e9a295d18a9");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow-x: hidden; }
  .root-app-wrapperv6.lander-page {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Flander-back-rays-desktop.png?alt=media&token=39fb27a4-62e8-41ee-90ba-9e9a295d18a9");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow-x: hidden; }
  .root-app-wrapperv2.lander-page {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Fbillie-dollar-money-desktopv2.png?alt=media&token=4b57ab0c-f814-49ef-bfef-ba288a4f3aad");
    background-position: top; }
  .root-app-wrapperv3.lander-page {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/consumerresearch-2.appspot.com/o/Real-Deal-Sweeps%2Fbackground%2Fbillion-dollar-desktopv3.png?alt=media&token=e7719b0a-9aca-4464-b40a-fcb5487bc469");
    background-position: top;
    background-color: unset; }
  .root-app-wrapper-halloween.lander-page {
    background-image: url("https://assets.realdealsweeps.com/images/realDealSweeps/background/halloween-lander-bg-desktop.png") !important; } }

@keyframes bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  10% {
    -webkit-transform: scale(1.1, 0.9) translateY(0);
            transform: scale(1.1, 0.9) translateY(0); }
  30% {
    -webkit-transform: scale(0.9, 1.1) translateY(-100px);
            transform: scale(0.9, 1.1) translateY(-100px); }
  50% {
    -webkit-transform: scale(1.05, 0.95) translateY(0);
            transform: scale(1.05, 0.95) translateY(0); }
  57% {
    -webkit-transform: scale(1, 1) translateY(-7px);
            transform: scale(1, 1) translateY(-7px); }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); } }

@keyframes shine {
  0% {
    background-position: right; } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fade {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; } }

@keyframes sweepright {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  50% {
    -webkit-transform: translateX(-70%);
            transform: translateX(-70%); } }

@keyframes sweepleft {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  50% {
    -webkit-transform: translateX(70%);
            transform: translateX(70%); } }

@keyframes wobble {
  0% {
    -webkit-transform: none;
            transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
            transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
            transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
            transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
            transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
            transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    -webkit-transform: none;
            transform: none; } }

@keyframes scroll {
  to {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

body {
  font-family: 'Inter', Arial, Helvetica, sans-serif; }

@keyframes bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  10% {
    -webkit-transform: scale(1.1, 0.9) translateY(0);
            transform: scale(1.1, 0.9) translateY(0); }
  30% {
    -webkit-transform: scale(0.9, 1.1) translateY(-100px);
            transform: scale(0.9, 1.1) translateY(-100px); }
  50% {
    -webkit-transform: scale(1.05, 0.95) translateY(0);
            transform: scale(1.05, 0.95) translateY(0); }
  57% {
    -webkit-transform: scale(1, 1) translateY(-7px);
            transform: scale(1, 1) translateY(-7px); }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); } }

@keyframes shine {
  0% {
    background-position: right; } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fade {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; } }

@keyframes sweepright {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  50% {
    -webkit-transform: translateX(-70%);
            transform: translateX(-70%); } }

@keyframes sweepleft {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  50% {
    -webkit-transform: translateX(70%);
            transform: translateX(70%); } }

@keyframes wobble {
  0% {
    -webkit-transform: none;
            transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
            transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
            transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
            transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
            transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
            transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    -webkit-transform: none;
            transform: none; } }

@keyframes scroll {
  to {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

.app-loader-image {
  -webkit-animation: bounce 1s;
          animation: bounce 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
          animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }

.grecaptcha-badge {
  display: none; }

