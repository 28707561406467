@import 'references/index';

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: $grey;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#react-survey-app .switch-wrapper {
    -webkit-animation: fadein 0.05s ease-in;
    -moz-animation: fadein 0s ease-in;
    animation: fadein 0.05s ease-in;
    animation-iteration-count: 1;
    height: 100vh;
}
