// Screen Breakpoints
$screen-max-xs: 320px;
$screen-max-sm: 600px;
$screen-max-md: 960px;
$screen-max-lg: 1280px;
$screen-max-xl: 1920px;

$screen-min-xs: 0;
$screen-min-sm: 321px;
$screen-min-md: 601px;
$screen-min-lg: 961px;
$screen-min-xl: 1281px;

// Colors
$black: rgba(20, 21, 22, 1);
$white: rgba(254, 254, 254, 1);
$grey: rgba(249, 249, 249, 1);
$primary-light: rgba(222, 243, 216, 1);
$primary-main: rgba(78, 166, 53, 1);
$primary-dark: rgba(45, 96, 31, 1);
$secondary-light: rgba(61, 153, 250, 1);
$secondary-main: rgba(20, 130, 247, 1);
$secondary-dark: rgba(8, 109, 218, 1);

$survey-title-color: rgba(7, 8, 10, 1);
