@keyframes bounce {
    0% {
        transform: scale(1, 1) translateY(0);
    }
    10% {
        transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
        transform: scale(0.9, 1.1) translateY(-100px);
    }
    50% {
        transform: scale(1.05, 0.95) translateY(0);
    }
    57% {
        transform: scale(1, 1) translateY(-7px);
    }
    64% {
        transform: scale(1, 1) translateY(0);
    }
    100% {
        transform: scale(1, 1) translateY(0);
    }
}

@keyframes shine {
    0% {
        background-position: right;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fade {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

@keyframes sweepright {
    0% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(-70%);
    }
}

@keyframes sweepleft {
    0% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(70%);
    }
}

@keyframes wobble {
    0% {
        transform: none;
    }
    15% {
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }
    30% {
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }
    45% {
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }
    60% {
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }
    75% {
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }
    100% {
        transform: none;
    }
}

@keyframes scroll {
    to {
        transform: translateY(0%);
    }
}
